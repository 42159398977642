export enum ExecutionStatus {
    Configuration = 'configuration',
    Idle = 'idle',
    Ready = 'ready',
    Updating = 'updating',
    Queued = 'queued',
    Running = 'running',
    Pending = 'pending',
    Cancelled = 'cancelled', // Execution cancelled by user
    Failed = 'failed', // Execution failed
    Completed = 'completed', // Execution finished successfuly
    Skipped = 'skipped', // Execution skipped for some reason
    Suspended = 'suspended',
}
