



import { computed, defineComponent, PropType } from '@vue/composition-api';
import apexchart from 'vue-apexcharts';

export default defineComponent({
    name: 'LineChart',
    props: {
        series: { type: Array as PropType<{ name: string; data: number[] }[]>, default: () => [] },
        xaxis: {
            type: Object as PropType<{
                categories: string[];
                title?: { text: string };
            }>,
            required: false,
        },
        yaxis: {
            type: Object as PropType<{
                categories: string[];
                title?: { text: string };
            }>,
            required: false,
        },
        title: {
            type: Object as PropType<{
                text: string;
                align: string;
            }>,
            required: false,
        },
        details: {
            type: Object as PropType<{
                markers?: number;
                stroke?: { curve?: string; width?: number };
                legend?: { show?: boolean; position?: string };
            }>,
            required: false,
        },
        lineColours: {
            type: Array as PropType<string[]>,
            default: () => ['#2b6cb0', '#d69e2e', '#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
        },
    },
    components: { apexchart },
    setup(props, { emit }) {
        const details: {
            markers: number;
            stroke: { curve: string; width: number };
            legend: { show: boolean; position: string };
        } = {
            markers: props.details?.markers || 0,
            stroke: {
                curve: props.details?.stroke?.curve || 'straight',
                width: props.details?.stroke?.width || 2,
            },
            legend: {
                show: props.details?.legend?.show || true,
                position: props.details?.legend?.position || 'bottom',
            },
        };
        const options = computed(() => {
            const chartOptions: {
                title?: { text: string; align?: string };
                xaxis?: { categories?: string[]; title?: { text: string } };
                yaxis?: { categories?: string[]; title?: { text: string } };
                markers: { size: number };
                stroke: { curve: string; width: number };
                legend: { show: boolean; position: string };
                colors: string[];
            } = {
                markers: {
                    size: details.markers,
                },
                stroke: details.stroke,
                legend: details.legend,
                colors: props.lineColours,
            };
            if (props.xaxis) chartOptions.xaxis = props.xaxis;
            if (props.yaxis) chartOptions.yaxis = props.yaxis;

            if (props.title) chartOptions.title = props.title;
            return chartOptions;
        });

        const chartClickEvent = (event: Event, chartContext: any, config: any) => {
            if (config.seriesIndex >= 0 && config.dataPointIndex >= 0)
                emit('data-point-clicked', { series: config.seriesIndex, dataPoint: config.dataPointIndex });
        };

        return { options, chartClickEvent };
    },
});
